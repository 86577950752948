@font-face {
  font-family: neucha-regular;
  src: url("Neucha-Regular.c27699e3.ttf");
  src: url("Neucha-Regular.c27699e3.ttf") format("embedded-opentype"), url("Neucha-Regular.c27699e3.ttf") format("truetype"), ;
}

html {
  font-family: neucha-regular;
}

@keyframes wrapper {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#wrapper {
  -ms-animation: wrapper 3s forwards;
  height: 100%;
  opacity: 0;
  width: 100%;
  animation: 3s forwards wrapper;
  position: fixed;
  top: 0;
  left: 0;
}

#bg {
  -ms-animation: bg 60s linear infinite;
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  width: 6750px;
  height: 100%;
  opacity: 1;
  background: #fff url("bg4.15152875.jpg") 0 0 / contain repeat-x;
  margin-left: -300px;
  animation: 60s linear infinite bg;
  top: 120px;
  left: 0;
  transform: translate3d(-200px, 0, 0);
}

@keyframes bg {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-2250px, 0, 0);
  }
}

@keyframes overlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#overlay {
  -ms-animation: overlay 1.5s 1.5s forwards;
  height: 100%;
  opacity: 0;
  width: 100%;
  background-image: url("overlay-pattern.831e4695.png"), url("overlay.669e9a23.svg");
  background-position: 0 0, center;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  background-attachment: fixed, fixed;
  animation: 1.5s 1.5s forwards overlay;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes header {
  0% {
    opacity: 0;
    transform: translate3d(0, 1em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

#header {
  -ms-animation: header .5s .75s forwards;
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  cursor: default;
  opacity: 0;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  text-shadow: 2px 2px #fff;
  animation: .5s .75s forwards header;
  display: inline-block;
  position: relative;
  top: -1em;
  transform: translate3d(0, 0, 0);
}

#header h1 {
  letter-spacing: -.035em;
  font-size: 4.35em;
  font-weight: 900;
  line-height: 1em;
}

#header nav {
  -ms-animation: nav-icons .25s ease-in-out forwards;
  backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  width: 100%;
  justify-content: center;
  animation: .25s ease-in-out forwards nav-icons;
  display: flex;
  transform: translate3d(0, 0, 0);
}

#header nav a {
  height: 2.5em;
  width: 2.5em;
  background-color: #ffffff80;
  border: 1px solid #434343;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px;
  font-size: 1.75em;
  display: flex;
}

/*# sourceMappingURL=index.62103871.css.map */
