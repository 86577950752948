@font-face {
    font-family: 'neucha-regular';
    src        : url('/assets/fonts/Neucha-Regular.ttf');
    src        : url('/assets/fonts/Neucha-Regular.ttf') format('embedded-opentype'),
        url('/assets/fonts/Neucha-Regular.ttf') format('truetype'),
}

html {
    font-family: "neucha-regular";
}

/* Wrapper */

@-moz-keyframes wrapper {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes wrapper {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes wrapper {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes wrapper {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#wrapper {
    -moz-animation   : wrapper 3s forwards;
    -webkit-animation: wrapper 3s forwards;
    -ms-animation    : wrapper 3s forwards;
    animation        : wrapper 3s forwards;
    height           : 100%;
    left             : 0;
    opacity          : 0;
    position         : fixed;
    top              : 0;
    width            : 100%;
}

/* BG */

#bg {
    -moz-animation             : bg 60s linear infinite;
    -webkit-animation          : bg 60s linear infinite;
    -ms-animation              : bg 60s linear infinite;
    animation                  : bg 60s linear infinite;
    -moz-backface-visibility   : hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility    : hidden;
    backface-visibility        : hidden;
    -moz-transform             : translate3d(0, 0, 0);
    -webkit-transform          : translate3d(0, 0, 0);
    -ms-transform              : translate3d(0, 0, 0);
    transform                  : translate3d(-200px, 0, 0);

    background       : #fff url("../images/bg4.jpg");
    background-repeat: repeat-x;
    background-size  : contain;
    width            : 6750px;
    height           : 100%;
    left             : 0;
    top              : 120px;
    opacity          : 1;
    margin-left      : -300px;
}

@-moz-keyframes bg {
    0% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }

    100% {
        -moz-transform   : translate3d(-2250px, 0, 0);
        -webkit-transform: translate3d(-2250px, 0, 0);
        -ms-transform    : translate3d(-2250px, 0, 0);
        transform        : translate3d(-2250px, 0, 0);
    }
}

@-webkit-keyframes bg {
    0% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }

    100% {
        -moz-transform   : translate3d(-2250px, 0, 0);
        -webkit-transform: translate3d(-2250px, 0, 0);
        -ms-transform    : translate3d(-2250px, 0, 0);
        transform        : translate3d(-2250px, 0, 0);
    }
}

@-ms-keyframes bg {
    0% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }

    100% {
        -moz-transform   : translate3d(-2250px, 0, 0);
        -webkit-transform: translate3d(-2250px, 0, 0);
        -ms-transform    : translate3d(-2250px, 0, 0);
        transform        : translate3d(-2250px, 0, 0);
    }
}

@keyframes bg {
    0% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
    }

    100% {
        -moz-transform   : translate3d(-2250px, 0, 0);
        -webkit-transform: translate3d(-2250px, 0, 0);
        -ms-transform    : translate3d(-2250px, 0, 0);
        transform        : translate3d(-2250px, 0, 0);
    }
}

/* Overlay */

@-moz-keyframes overlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes overlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes overlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes overlay {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#overlay {
    -moz-animation       : overlay 1.5s 1.5s forwards;
    -webkit-animation    : overlay 1.5s 1.5s forwards;
    -ms-animation        : overlay 1.5s 1.5s forwards;
    animation            : overlay 1.5s 1.5s forwards;
    background-attachment: fixed, fixed;
    background-image     : url("../images/overlay-pattern.png"), url("../images/overlay.svg");
    background-position  : top left, center center;
    background-repeat    : repeat, no-repeat;
    background-size      : auto, cover;
    height               : 100%;
    left                 : 0;
    opacity              : 0;
    position             : fixed;
    top                  : 0;
    width                : 100%;
}


/* Header */

@-moz-keyframes header {
    0% {
        -moz-transform   : translate3d(0, 1em, 0);
        -webkit-transform: translate3d(0, 1em, 0);
        -ms-transform    : translate3d(0, 1em, 0);
        transform        : translate3d(0, 1em, 0);
        opacity          : 0;
    }

    100% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        opacity          : 1;
    }
}

@-webkit-keyframes header {
    0% {
        -moz-transform   : translate3d(0, 1em, 0);
        -webkit-transform: translate3d(0, 1em, 0);
        -ms-transform    : translate3d(0, 1em, 0);
        transform        : translate3d(0, 1em, 0);
        opacity          : 0;
    }

    100% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        opacity          : 1;
    }
}

@-ms-keyframes header {
    0% {
        -moz-transform   : translate3d(0, 1em, 0);
        -webkit-transform: translate3d(0, 1em, 0);
        -ms-transform    : translate3d(0, 1em, 0);
        transform        : translate3d(0, 1em, 0);
        opacity          : 0;
    }

    100% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        opacity          : 1;
    }
}

@keyframes header {
    0% {
        -moz-transform   : translate3d(0, 1em, 0);
        -webkit-transform: translate3d(0, 1em, 0);
        -ms-transform    : translate3d(0, 1em, 0);
        transform        : translate3d(0, 1em, 0);
        opacity          : 0;
    }

    100% {
        -moz-transform   : translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform    : translate3d(0, 0, 0);
        transform        : translate3d(0, 0, 0);
        opacity          : 1;
    }
}

#header {
    -moz-animation             : header 0.5s 0.75s forwards;
    -webkit-animation          : header 0.5s 0.75s forwards;
    -ms-animation              : header 0.5s 0.75s forwards;
    animation                  : header 0.5s 0.75s forwards;
    -moz-backface-visibility   : hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility    : hidden;
    backface-visibility        : hidden;
    -moz-transform             : translate3d(0, 0, 0);
    -webkit-transform          : translate3d(0, 0, 0);
    -ms-transform              : translate3d(0, 0, 0);
    transform                  : translate3d(0, 0, 0);
    cursor                     : default;
    display                    : inline-block;
    opacity                    : 0;
    position                   : relative;
    text-align                 : center;
    top                        : -1em;
    vertical-align             : middle;
    width                      : 100%;
    text-shadow: 2px 2px #fff;
}

#header h1 {
    font-size     : 4.35em;
    font-weight   : 900;
    letter-spacing: -0.035em;
    line-height   : 1em;
}

#header nav {
    -moz-animation             : nav-icons 0.25s ease-in-out forwards;
    -webkit-animation          : nav-icons 0.25s ease-in-out forwards;
    -ms-animation              : nav-icons 0.25s ease-in-out forwards;
    animation                  : nav-icons 0.25s ease-in-out forwards;
    -moz-backface-visibility   : hidden;
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility    : hidden;
    backface-visibility        : hidden;
    -moz-transform             : translate3d(0, 0, 0);
    -webkit-transform          : translate3d(0, 0, 0);
    -ms-transform              : translate3d(0, 0, 0);
    transform                  : translate3d(0, 0, 0);
    display                    : flex;
    justify-content            : center;
    width                      : 100%
}

#header nav a {
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius   : 100%;
    border          : solid 1px rgb(67, 67, 67);
    font-size       : 1.75em;
    height          : 2.5em;
    width           : 2.5em;
    margin          : 5px;
}